@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: 'VT323';
  src:  url('/assets/fonts/VT323-Regular.ttf') format('truetype');
  font-display: block;
}
// @font-face {
//   font-family: 'PressStart2P';
//   src:  url('/assets/fonts/PressStart2P.ttf') format('truetype'),
// }

//Theme Color
// $purple: #575cae;
// $orange: #ff6d41;
// $pink: #fe7f7f;
// $theme-red: #ff502c;
// $theme-yellow: #ffae13;

$yt-red: #ed462f;
$white-7: rgba(255,255,255,0.7);
$theme-primary: #ff6d41;
$theme-second: #575cae;

:root {
  // --theme-primary: #ff6d41;
  // --theme-second: #575cae;
  --theme-primary: #eceeff;
  --theme-second: #575DD2;

  --black: #555;
  --active-blue: #073769;
  --white-7: rgba(255,255,255,0.7);
  --yt-red: #ed462f;

}

.home-page{
  // &.theme-blueberry {
  //   --theme-primary: #575cae;
  //   --theme-second: #fe7f7f;
  // }
  // &.theme-orange{
  //   --theme-primary: #ff6d41;
  //   --theme-second: #575cae;
  // }
  // &.theme-tomato{
  //   --theme-primary: #ff502c;
  //   --theme-second: #575cae;
  // }
  // &.theme-cheese{
  //   --theme-primary: #ffae13;
  //   --theme-second: #575cae;
  // }
}

.test-box{
  display: block;
  padding: 30px;
  background-color: var(--theme-primary);
  span{
    color: var(--theme-second);
  }
}

html{
  // max-height: 100vh;
  // height: -webkit-fill-available;
  // height: -moz-available;
  min-height: 100vh;
}

body {
  font-family: sans-serif, Helvetica;
  // color: var(--black);
  color: var(--white-7);

  user-select: none;
  box-sizing: border-box;
  min-height: 100vh;
  // height: 100vh;
  // max-height: -webkit-fill-available;
  background-color: #eceeff;
  touch-action: manipulation;
  // &.theme-blueberry {
  //   background-color: #575cae;
  // }
  // &.theme-orange{
  //   background-color: #ff6d41;
  // }
  // &.theme-tomato{
  //   background-color: #ff502c;
  // }
  // &.theme-cheese{
  //   background-color: #ffae13;
  // }
  &.animal-crossing{
    color: var(--black);
  }
}

a:not([href]) {
  color: inherit;
  text-decoration: none;

  :hover {
    color: inherit;
    text-decoration: none;
  }
}

/* font */
.font-custom{
  // font-family: 'PressStart2P';
  font-family: 'VT323';
}
.black{
  color:black;
}
.white-7{
  // color: var(--white-7);
  color: var(--theme-second);
}
.second-color{
  color: var(--theme-second);
  // text-shadow: 0px 0px 1px #d49d9d;
  text-shadow: -1px -1px #fff, -1.5px -1.5px #fff, -2px -2px #9cf79c;
}
.second-color-bg{
  background-color: var(--theme-second);
}
.primary-color{
  color: var(--theme-primary);
}
.theme-bg{
  background: var(--theme-primary);
}
.yt-red{
  color: var(--yt-red);
  &.yt-bg{
    background: rgba(255,255,255,0.8);
    border-radius: 5px;
    padding: 0px 7px;
    white-space: nowrap;
  }
}
.font-xs{
  font-size:12px;
  font-size: 0.8rem; //12px;
}
.font-s{
  font-size: 14px;
  font-size: 0.9rem;
}
.font-m{
  font-size: 16px;
  font-size: 0.95rem;
}
.font-l{
  font-size: 18px;
  font-size: 1rem;
}
.font-xl{
  font-size: 20px;
  font-size: 1.25rem;
}
.font-xxl{
  font-size: 24px;
  font-size: 1.3rem;
}
.fw-500{
  font-weight: 500;
}
.fw-600{
  font-weight: 600;
}
.fw-700, .bold{
  font-weight: 700;
}
.text-underline{
  text-decoration: underline;
}
/* font end */

/* Transform */
.list-square{
  padding-left:15px; //ul
  li{
    list-style: square;
  }
}
.rotate-15{
  transform: rotate(15deg);
}
.white-border{
  border: 10px solid white;
}
/* Transform end */


/* page setting */
.page-container{
  height: 100vh;
  max-height: -webkit-fill-available;
}
.section-container {
  padding: 3% 5% 0 5%;
  width: 100%;
  // height: 100vh;
  // max-height: -webkit-fill-available;
}

.hide-window{
  display: block;
  opacity: 0;
  height:0;
  width:0;
  overflow: hidden;
  transition: opacity 0.3s ease;
  .close-btn{
    display: none;
  }
}

.show-window{
  display: block;
  opacity: 1;
  position: fixed;
  top:0%;
  left:0%;
  width:100%;
  height:100%;
  transition: opacity 0.3s ease;
  z-index: 999;
  .close-btn{
    z-index: 2;
    display: inline-block;
    position: fixed;
    cursor: pointer;
    right: 1%;
    top: 1%;
    text-shadow: 2px 2px white;
    font-size: 30px;
    &:hover{
      text-shadow: none;
      margin-left: 2px;
      margin-top: 2px;
    }
  }
}

.btn:focus {
  box-shadow: none;
}

.custom-btn{
  border: 2px solid var(--theme-second);
  display: inline-block;
  padding: 8px 12px;
  text-align: center;
  background: white;
  box-shadow: 3px 3px 1px #505050;
  margin: 0 3px 20px -3px;
  border-radius: 6px;
  font-size: 28px;
  font-family: 'VT323';
  line-height: 20px;

  &:hover{
    outline: none;
    box-shadow: none;
    margin: 3px 0 17px 0;
  }
  &:focus{
    outline: none;
    box-shadow: 3px 3px 1px #505050;
    &:hover{
      box-shadow: none;
    }
  }
  &:disabled{
    outline: none;
    margin-left: 3px;
    margin-top: 3px;
    box-shadow: none;
    margin-bottom: 17px;
  }
}

.link-ins{
  font-size: 24px;
  // padding: 10px;
  // border-radius: 10px;
  // line-height: 26px;
  // margin: 0 3px 3px 0;
  // background-color: var(--theme-second);
  // box-shadow: 3px 3px 1px #505050;
  // border: 2px solid white;
  // &:hover{
  //   margin: 3px 0 0 3px;
  //   box-shadow: none;
  // }
}

.opacity-0{
  opacity: 0;
}

.youtube-iframe {
  width: 90vw;
  height: 50.625vw;
}

.coming-soon-wrap{
  font-family: "VT323";
  font-size: 6.3rem;
  line-height: 6.3rem;
  color: var(--theme-second);
  text-shadow: -4px -4px 1px white, 
                -3px -3px 1px white,
                -2px -2px 1px white, 
                -1px -1px 1px white,
                0px 0px 1px white, 
                -5px -5px 1px #9cf79c;
  //text-shadow: 3px 3px 1px #d49d9d;
}

.c-text-shadow {
  text-shadow: 1px 1px #f3f3f3;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237868E6'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237868E6'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

@media only screen and (max-width: 780px) {
  .link-ins{
    font-size: 18px;
  }  
}

@media only screen and (max-width: 414px) {
  .section-container {
    padding-top: 5%;
    padding-bottom: 0%;
  }
}

@media only screen and (max-width: 380px) {
  .white-border {
    border: 4px solid white;
  }
}
